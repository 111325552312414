import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { HttpTokenInterceptor } from './http.token.interceptor';
import { ApiService } from './api.service';
import { AuthGuard } from './auth-guard.service';
import { JwtService } from './jwt.service';
import { UserService } from './user.service';
import { AuthGuardChild } from './auth-guard-child.service';
import { WinSizeService } from './winsize.service';

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptor, multi: true },
    ApiService,
    AuthGuard,
    AuthGuardChild,
    JwtService,
    UserService,
    WinSizeService
  ],
  declarations: []
})
export class CoreModule { }