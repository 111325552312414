import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

const routes: Routes = [
  {
    path: 'prodindicators',
    loadChildren: './views/0-prodindicators/prodindicators.module#ProdIndicatorsModule'
  },
  {
    path: 'operindicators',
    loadChildren: './views/1-operindicators/operindicators.module#OperIndicatorsModule'
  },
  {
    path: 'procmanage',
    loadChildren: './views/2-procmanage/procmanage.module#ProcManageModule'
  },
  {
    path: 'storemanage',
    loadChildren: './views/3-storemanage/storemanage.module#StoreManageModule'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
