import { Component, OnInit, AfterViewInit, ElementRef, AfterContentInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { Errors } from '../../models/errors.model';
import { UserService } from '../../core/user.service';

@Component({
  selector: 'app-auth-page',
  templateUrl: './auth.component.html',
  styles: [ `
    .login-form {
      max-width: none;
    }
    .login-form-button {
      width: 100%;
    }
    .ant-row {
      text-align: center;
    }
    .ant-form .ant-form-item-children
      width: 100% !important;
    }

    .has-error .ant-form-explain {
      height: 100vh;
    }
    .dummybar {
    }
  `
  ]
})
export class AuthComponent implements OnInit, AfterContentInit {

  errors: Errors = {errors: {}};
  // isSubmitting = false;
  authForm: FormGroup;
  isLoading = false;
  isVisible = false;

  constructor(private route: ActivatedRoute, 
    private router: Router, private userService: UserService, private fb: FormBuilder, private elRef:ElementRef) {
  }

  ngAfterContentInit() {
    
  }


  ngOnInit() {
    // this.route.url.subscribe(data => {});

    this.authForm = this.fb.group({
      userid: [ null, [ Validators.required ] ],
      password: [ null, [ Validators.required ] ],
      remember: [ true ]
    });

    this.showModal();

    
  }

  submitForm(): void {
    for (const i in this.authForm.controls) {
      this.authForm.controls[ i ].markAsDirty();
      this.authForm.controls[ i ].updateValueAndValidity();
    }
    
    if (this.authForm.valid) {
      // this.isSubmitting = true;
      this.errors = {errors: {}};
      this.isLoading = true;
      const credentials = this.authForm.value;
      // console.log('전송될 정보 : ', credentials);


      this.userService
      .attemptAuth('login', credentials)
      .subscribe(
        data => {
          this.isLoading = false;
          this.router.navigateByUrl('/')
        },
        err => {
          // todo: 에러 메시지 타입에 맞추어야 한다.
          // console.log('error', err);
          this.errors = {errors: {message: err.message}};
          // this.isSubmitting = false;
          this.isLoading = false;
        }
      );
    }
  }
  
  

  showModal(): void {
    this.isVisible = true;
  }

  afterOpen():void {
    // var div = this.elRef.nativeElement.querySelector('.ant-form-item-children');
    // console.log( div);
  }
}
