import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';

import { UserService } from './core/user.service';
import { WinSizeService } from './core/winsize.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  host: {
    '(window:resize)': 'onResize($event)'
  },
  styles: [`
    /*전체 레이아웃*/
    :host ::ng-deep .ant-layout {
      height: 100%;
      background-color: #fff;
    }
    /*메인 컨텐츠*/
    :host ::ng-deep .ant-layout-content {
      padding: 20px;
      border-left: 1px solid #eee;
      border-top: 1px solid #eee;
      border-bottom: 1px solid #eee;
      overflow: scroll;
      overflow-x: hidden;
      background-color: #CED6DF;
    }

    

    /*헤더*/
    :host ::ng-deep .ant-layout-header {
      border-left: 1px solid #eee;
      background-color: #fff;
      padding: 0;
    }
    /*헤더 - 로고*/
    :host ::ng-deep .logo {
      height: 32px;
      background: rgba(255, 255, 255, .2);
      margin: 16px;
      text-align: center;
    }
    .doonalogo {
      position: relative;
      display: inline-block;
      width: 130px;
      top: -6px;
    }
    :host ::ng-deep .ant-avatar.ant-avatar-icon {
      float: right;
      margin: 20px;
      background-color: #cfd7df;
    }


    /*푸터*/
    :host ::ng-deep .ant-layout-footer {
      background-color: #fff;
      padding: 12px;
      font-size: 10px;
      text-align: center;
      border-left: 1px solid #eee;

    }


    
    /*사이드바*/
    :host ::ng-deep .ant-layout-sider {
      background-color: #fff;
      overflow: scroll;
      overflow-x: hidden;
      -ms-overflow-style: none;
    }
    ::-webkit-scrollbar {
      display:none;
    }
    /*이거 왜 생기는지 모르겠다.*/
    :host ::ng-deep .ant-layout-sider-trigger {
      background: none;
      display: none;
    }
    /*사이드바 트리거 버튼*/
    :host ::ng-deep .trigger {
      font-size: 18px;
      line-height: 72px;
      padding: 0 24px;
      cursor: pointer;
      transition: color .3s;
    }
    :host ::ng-deep .trigger:hover {
      color: #1890ff;
    }

    


  /*서브하위메뉴*/
  .ant-menu-inline .ant-menu-item, .ant-menu-inline .ant-menu-submenu-title, .ant-menu-vertical .ant-menu-item, .ant-menu-vertical .ant-menu-submenu-title, .ant-menu-vertical-left .ant-menu-item, .ant-menu-vertical-left .ant-menu-submenu-title, .ant-menu-vertical-right .ant-menu-item, .ant-menu-vertical-right .ant-menu-submenu-title {
    height: 25px;
    font-size: 14px;
    line-height: 25px;
    margin: 0;
  }
  /*선택된서브메뉴 - 배경색*/
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #fff;
  }
  /*선택된서브메뉴 - 우측보더*/
  .ant-menu-inline .ant-menu-item:after, .ant-menu-vertical .ant-menu-item:after, .ant-menu-vertical-left .ant-menu-item:after, .ant-menu-vertical-right .ant-menu-item:after{
    border-right: 5px solid #666;
  }
  /*선택된서브메뉴 - 글자색*/
  .ant-menu-item-active, .ant-menu-item-selected, .ant-menu-item-selected>a, .ant-menu-item-selected>a:hover, .ant-menu-item:hover, .ant-menu-item>a:hover, .ant-menu-submenu-active, .ant-menu-submenu-title:hover, .ant-menu-vertical .ant-menu-submenu-selected, .ant-menu-vertical .ant-menu-submenu-selected>a, .ant-menu-vertical-left .ant-menu-submenu-selected, .ant-menu-vertical-left .ant-menu-submenu-selected>a, .ant-menu-vertical-right .ant-menu-submenu-selected, .ant-menu-vertical-right .ant-menu-submenu-selected>a, .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open {
    color: #666;
  }
  .ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
    border-right: none;
    border-top: 1px solid #eee;
    padding-top: 10px;
  }

  `]
})
export class AppComponent implements OnInit {
  constructor (private userService: UserService, private winSizeService: WinSizeService) {}

  ngOnInit() {
    this.userService.populate();
    this.winSizeService.triggerResize();
  }

  openMap = {
    sub1: true,
    sub2: false,
    sub3: false,
    sub4: false
  };
  isCollapsed = false;

  openHandler(value: string): void {
    for (const key in this.openMap) {
      if (key !== value) {
        // this.openMap[ key ] = false;
      }
    }
  }

  toggleCollapsed(): void {
    this.isCollapsed = !this.isCollapsed;
  }

  logout() {
    console.log('sdv');
    this.userService.purgeAuth(); // 자동으로 isAuthenticated 상태가 전파됨으로 구독하고 있는 옵저버들에게 영향이 미친다.
  }

  onResize(event){
    // console.log('RESIZE : ', event.target.innerWidth, event.target.innerHeight);
    this.winSizeService.resize(event.target.innerWidth, event.target.innerHeight);
  }

}
