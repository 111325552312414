import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-layout-nav',
  templateUrl: './nav.component.html',
  styles: [`

  .wrap-side-nav {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;
    overflow: scroll;
    overflow-x: hidden;
  }
  :host ::ng-deep .ant-layout-sider {
    background-color: #fff;
    width: 240px;
  }

  :host ::ng-deep .trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color .3s;
  }

  :host ::ng-deep .trigger:hover {
    color: #1890ff;
  }

  :host ::ng-deep .logo {
    height: 32px;
    background: rgba(255, 255, 255, .2);
    margin: 16px;
  }

  `]
})
export class NavComponent implements OnInit {
  constructor() {}
  openMap = {
    sub1: true,
    sub2: false,
    sub3: false,
    sub4: false
  };
  isCollapsed = false;
  triggerTemplate = null;
  @ViewChild('trigger') customTrigger: TemplateRef<void>;



  openHandler(value: string): void {
    for (const key in this.openMap) {
      if (key !== value) {
        this.openMap[ key ] = false;
      }
    }
  }
  // isCollapsed = false;

  toggleCollapsed(): void {
    this.isCollapsed = !this.isCollapsed;
  }
  
  ngOnInit() {}
}

  // /*사이드메뉴 전체*/
  // .sidenav {
  //   border-right: none;
  // }
  // .ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
  //   border-right: none;
  // }


  // /*서브하위메뉴*/
  // .ant-menu-inline .ant-menu-item, .ant-menu-inline .ant-menu-submenu-title, .ant-menu-vertical .ant-menu-item, .ant-menu-vertical .ant-menu-submenu-title, .ant-menu-vertical-left .ant-menu-item, .ant-menu-vertical-left .ant-menu-submenu-title, .ant-menu-vertical-right .ant-menu-item, .ant-menu-vertical-right .ant-menu-submenu-title {
  //   height: 25px;
  //   font-size: 12px;
  //   line-height: 25px;
  //   margin: 0;
  // }
  // /*선택된서브메뉴 - 배경색*/
  // .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  //   background-color: #fff;
  // }
  // /*선택된서브메뉴 - 우측보더*/
  // .ant-menu-inline .ant-menu-item:after, .ant-menu-vertical .ant-menu-item:after, .ant-menu-vertical-left .ant-menu-item:after, .ant-menu-vertical-right .ant-menu-item:after{
  //   border-right: 5px solid #666;
  // }
  // /*선택된서브메뉴 - 글자색*/
  // .ant-menu-item-active, .ant-menu-item-selected, .ant-menu-item-selected>a, .ant-menu-item-selected>a:hover, .ant-menu-item:hover, .ant-menu-item>a:hover, .ant-menu-submenu-active, .ant-menu-submenu-title:hover, .ant-menu-vertical .ant-menu-submenu-selected, .ant-menu-vertical .ant-menu-submenu-selected>a, .ant-menu-vertical-left .ant-menu-submenu-selected, .ant-menu-vertical-left .ant-menu-submenu-selected>a, .ant-menu-vertical-right .ant-menu-submenu-selected, .ant-menu-vertical-right .ant-menu-submenu-selected>a, .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open {
  //   color: #666;
  // }