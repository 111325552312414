import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../core/user.service';
import { WinSizeService } from 'src/app/core/winsize.service';
import {
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';


@Component({
  selector: 'app-home-page',
  templateUrl: './home.component.html',
  styles:[/*css*/`
    // .ant-row{border: 1px solid red;}
    // div[nzspan]{border: 1px solid blue;}
    .increase-box {
      width: 100%; 
      height: 145px; 
      background-color:#fff; 
      color: #999; 
      box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.1); 
      border-radius: 4px;
      margin-bottom: 10px;
      /*
      background: #3191d6;
      background: -moz-linear-gradient(left, #3191d6 0%, #7870d9 40%, #ac55d6 100%);
      background: -webkit-linear-gradient(left, #3191d6 0%,#7870d9 40%,#ac55d6 100%);
      background: linear-gradient(to right, #3191d6 0%,#7870d9 40%,#ac55d6 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3191d6', endColorstr='#ac55d6',GradientType=1 );
      */    
    }
    .increase-box .title {
      height: auto;
      padding: 10px 10px 2px 10px;
    }
    .increase-box h4 {
      color: #999;
    }
    .increase-box .amt{
      font-family: 'Roboto', sans-serif;
      font-size: 30px; 
      // font-weight: bold; 
      text-align: center;
      // text-indent: 0px;
    }
    .increase-box .per {
      float: right;
      display: inline-block;
      position: relative;
      width: 70px;
      font-family: 'Roboto', sans-serif;
      font-size: 14px;
      line-height: 14px;
      font-weight: bold;
      margin-top: 10px;
      margin-right: 10px;
      text-align: center;
      padding: 2px 0px;
      border: 1px solid #eee;
      border-radius: 50px;
      background-color: #fff;
    }
    .increase-box .desc {
      font-family: 'Roboto', sans-serif;
      font-size: 12px;
      display: inline-block;
      position: relative;
      top: 12px;
      margin-right: 5px;
      float: right;
    }
    .dummy-box{
     width: 100%;
      height: 145px; 
      display:inline-block; 
      position: relative; 
      background-color:#2ac7ca;
      margin-bottom: 10px;
      transition: * .2s;
    }
    .graph-box{
      width:100%; 
      // min-height:300px;
      height: 300px;
      background-color: #fff; 
      display:inline-block; 
      position: relative;
      overflow: hidden;
      box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.1); 
      border-radius: 4px;
      margin-bottom: 5px;
    }
    .graph-box2{
      width:100%; 
      min-height:145px; 
      margin-bottom: 5px;
      background-color: #fff; 
      display:inline-block; 
      position: relative;
      overflow: hidden;
      box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.1); 
      border-radius: 4px;
    }
    .graph-box3{
      width:100%; 
      // height: 100%;
      height: 300px;
      margin-bottom: 5px;
      background-color: #fff; 
      display:inline-block; 
      position: relative;
      overflow: hidden;
      box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.1); 
      border-radius: 4px;
    }
    .graph-box3 .titlebar {
      position: relative;
      display: inline-block;
      width: 100%;
      height: 40px;
      //border-bottom: 1px solid #999;
      background-color: #f5f5f5;
      text-align: center;
      //box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
    }
    .graph-box3 .titlebar .title {
      font-size: 15px; 
      line-height: 40px;
      // font-weight: bold; 
      color: #9a9a9a;
    }
    .graph-box3 .container {
      display: block;
      width: 100%;
      height: 150px;
      position: relative;
      left: -17px;
      top: 10px;
    }
    .graph-box3 .morebtn {
      display: block;
      position: relative;
      text-align: center;
      top: 10%;
    }
    .graph-box3 button {
      font-size: 12px;
      width: 70%;
      height: 24px;
      color: #0094d2;
      border-color: #ddd;
      font-size: 12px;
    }


    .dateform .title h6 {
      display: inline-block;
      position: relative;
      top: 7px;
      left: 17px;
      color: #999;
    }

  `]
})
export class HomeComponent implements OnInit {

  constructor(private router: Router, private userService: UserService, private winSizeService: WinSizeService, private fb: FormBuilder) {}
  
  isAuthenticated: boolean;

  ngOnInit() {
    this.userService.isAuthenticated.subscribe(
      (authenticated) => {
        this.isAuthenticated = authenticated;
        // console.log('로그인 상태 : ', this.isAuthenticated)
        if (authenticated) {
          // this.setListTo('feed');
        } else {
          // this.setListTo('all');
          this.router.navigateByUrl('/login');
        }
      }
    );

    this.winSizeService.size$.subscribe(
      (size) => {
        // console.log(size);
      }
    );

    this.validateForm = this.fb.group({
      datePicker     : [ null ],
      datePickerTime : [ null ],
      monthPicker    : [ null ],
      rangePicker    : [ [] ],
      rangePickerTime: [ [] ],
      timePicker     : [ null ]
    });
 
  }

  setListTo(type: string = '', filters: Object = {}) {
    if (type === 'feed' && !this.isAuthenticated) {
      this.router.navigateByUrl('/login');
      return;
    }
  }

  public barChartOptions:any = {
    scaleShowVerticalLines: false,
    responsive: true
  };
  public barChartLabels:string[] = ['2006', '2007', '2008', '2009', '2010', '2011', '2012'];
  public barChartType:string = 'bar';
  public barChartLegend:boolean = true;
 
  public barChartData:any[] = [
    {data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A', color: '#fff'},
    {data: [28, 48, 40, 19, 86, 27, 90], label: 'Series B'}
  ];
 
  // events
  public chartClicked(e:any):void {
    console.log(e);
  }
 
  public chartHovered(e:any):void {
    console.log(e);
  }

  chartOptions = {
    responsive: true
  };
  chartData = [
    { data: [330, 600, 260, 700], label: 'Account A' },
    { data: [120, 455, 100, 340], label: 'Account B' },
    { data: [45, 67, 800, 500], label: 'Account C' }
  ];

  chartColors = [
    { 
      backgroundColor:["#FF7360", "#6FC8CE", "#FAFFF2", "#FFFCC4", "#B9E8E0"] 
    }];


  chartLabels = [];

  onChartClick(event) {
    console.log(event);
  }




  single: any[] =[
    {
      "name": "United States",
      "value": 49737
    },
    {
      "name": "France",
      "value": 36745
    },
    {
      "name": "United Kingdom",
      "value": 36240
    },
    {
      "name": "Spain",
      "value": 33000
    },
    {
      "name": "Italy",
      "value": 35800
    },
    {
      "name": "Barbados",
      "value": 57128
    },
    {
      "name": "United States",
      "value": 49737
    },
    {
      "name": "France",
      "value": 36745
    },
    {
      "name": "United Kingdom",
      "value": 36240
    },
    {
      "name": "Spain",
      "value": 33000
    },
    {
      "name": "Italy",
      "value": 35800
    },
    {
      "name": "Barbados",
      "value": 57128
    }
  ];
  
  multi: any[] =
  [
    {
      "name": "Norway",
      "series": [
        {
          "value": 2903,
          "name": "2016-09-23T04:45:44.460Z"
        },
        {
          "value": 6492,
          "name": "2016-09-19T13:37:44.577Z"
        },
        {
          "value": 5203,
          "name": "2016-09-15T20:20:52.710Z"
        },
        {
          "value": 4306,
          "name": "2016-09-21T09:56:15.598Z"
        },
        {
          "value": 4942,
          "name": "2016-09-19T20:24:11.327Z"
        },
        {
          "name": "2016-09-23T15:50:06.795Z",
          "value": 6374
        },
        {
          "name": "2016-09-23T04:29:59.099Z",
          "value": 5749
        },
        {
          "name": "2016-09-13T00:14:00.367Z",
          "value": 2159
        },
        {
          "name": "2016-09-15T01:21:43.017Z",
          "value": 6969
        }
      ]
    },
    {
      "name": "Taiwan",
      "series": [
        {
          "value": 5101,
          "name": "2016-09-23T04:45:44.460Z"
        },
        {
          "value": 4063,
          "name": "2016-09-19T13:37:44.577Z"
        },
        {
          "value": 6834,
          "name": "2016-09-15T20:20:52.710Z"
        },
        {
          "value": 2886,
          "name": "2016-09-21T09:56:15.598Z"
        },
        {
          "value": 4589,
          "name": "2016-09-19T20:24:11.327Z"
        },
        {
          "name": "2016-09-23T15:50:06.795Z",
          "value": 2739
        },
        {
          "name": "2016-09-23T04:29:59.099Z",
          "value": 5796
        },
        {
          "name": "2016-09-13T00:14:00.367Z",
          "value": 2378
        },
        {
          "name": "2016-09-15T01:21:43.017Z",
          "value": 5997
        }
      ]
    },
    {
      "name": "Kiribati",
      "series": [
        {
          "value": 3714,
          "name": "2016-09-23T04:45:44.460Z"
        },
        {
          "value": 3451,
          "name": "2016-09-19T13:37:44.577Z"
        },
        {
          "value": 3936,
          "name": "2016-09-15T20:20:52.710Z"
        },
        {
          "value": 6288,
          "name": "2016-09-21T09:56:15.598Z"
        },
        {
          "value": 2878,
          "name": "2016-09-19T20:24:11.327Z"
        },
        {
          "name": "2016-09-23T15:50:06.795Z",
          "value": 5626
        },
        {
          "name": "2016-09-23T04:29:59.099Z",
          "value": 6612
        },
        {
          "name": "2016-09-13T00:14:00.367Z",
          "value": 6467
        },
        {
          "name": "2016-09-15T01:21:43.017Z",
          "value": 6711
        }
      ]
    },
    {
      "name": "Curaçao",
      "series": [
        {
          "value": 5771,
          "name": "2016-09-23T04:45:44.460Z"
        },
        {
          "value": 5130,
          "name": "2016-09-19T13:37:44.577Z"
        },
        {
          "value": 5474,
          "name": "2016-09-15T20:20:52.710Z"
        },
        {
          "value": 2239,
          "name": "2016-09-21T09:56:15.598Z"
        },
        {
          "value": 5620,
          "name": "2016-09-19T20:24:11.327Z"
        },
        {
          "name": "2016-09-23T15:50:06.795Z",
          "value": 4318
        },
        {
          "name": "2016-09-23T04:29:59.099Z",
          "value": 4540
        },
        {
          "name": "2016-09-13T00:14:00.367Z",
          "value": 2588
        },
        {
          "name": "2016-09-15T01:21:43.017Z",
          "value": 4255
        }
      ]
    },
    {
      "name": "Sint Maarten (Dutch Part)",
      "series": [
        {
          "value": 3463,
          "name": "2016-09-23T04:45:44.460Z"
        },
        {
          "value": 4980,
          "name": "2016-09-19T13:37:44.577Z"
        },
        {
          "value": 6279,
          "name": "2016-09-15T20:20:52.710Z"
        },
        {
          "value": 4470,
          "name": "2016-09-21T09:56:15.598Z"
        },
        {
          "value": 3466,
          "name": "2016-09-19T20:24:11.327Z"
        },
        {
          "name": "2016-09-23T15:50:06.795Z",
          "value": 3593
        },
        {
          "name": "2016-09-23T04:29:59.099Z",
          "value": 5080
        },
        {
          "name": "2016-09-13T00:14:00.367Z",
          "value": 2065
        },
        {
          "name": "2016-09-15T01:21:43.017Z",
          "value": 6097
        }
      ]
    }
  ]
  ;

  view: any[] = [700, 400];

  // options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = true;
  showXAxisLabel = true;
  xAxisLabel = 'Country';
  showYAxisLabel = true;
  yAxisLabel = 'Population';

  colorScheme = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
  };

  onSelect(event) {
    console.log(event);
  }


  dataSet = [
    {
      key    : '1',
      name   : 'John Brown',
      age    : 32,
      address: 'New York No. 1 Lake Park'
    },
    {
      key    : '2',
      name   : 'Jim Green',
      age    : 42,
      address: 'London No. 1 Lake Park'
    },
    {
      key    : '3',
      name   : 'Joe Black',
      age    : 32,
      address: 'Sidney No. 1 Lake Park'
    }
  ];

  validateForm: FormGroup;
  singleValue;
  rangeValue;


  public lineChartData:Array<any> = [
    [65, 59, 80, 81, 56, 55, 40],
    [28, 48, 40, 19, 86, 27, 90]
  ];
  public lineChartLabels:Array<any> = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
  public lineChartType:string = 'line';
  public pieChartType:string = 'pie';
  

}

/*
진한녹색 2ac7ca 

회색배경 #CED6DF

녹색 1 : #4CAE51
보라 1 : #9675CE
파랑 1 : #00A9F3
다홍 1 : #F96262
*/