import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { AuthModule } from './views/auth/auth.module';
import { HomeModule } from './views/home/home.module';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';

import { HeaderComponent, FooterComponent, NavComponent } from './shared/layout';

import { NZ_I18N, ko_KR } from 'ng-zorro-antd';
import { registerLocaleData } from '@angular/common';
import ko from '@angular/common/locales/ko';
registerLocaleData(ko);

@NgModule({
  declarations: [
    AppComponent, HeaderComponent, FooterComponent, NavComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    SharedModule,
    HomeModule,
    AuthModule,
    AppRoutingModule
  ],
  providers: [{provide: NZ_I18N, useValue: ko_KR}],
  bootstrap: [AppComponent]
})
export class AppModule { }
