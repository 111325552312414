import { Injectable } from '@angular/core';
import { Observable ,  BehaviorSubject ,  ReplaySubject } from 'rxjs';
import { map ,  distinctUntilChanged } from 'rxjs/operators';

@Injectable()
export class WinSizeService {
    private size = new ReplaySubject<string>(1);
    public size$ = this.size.asObservable().pipe(distinctUntilChanged());;

    private sizelist: Array<string> = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'];

    constructor () {}

    triggerResize():void {
        let evt;
            if(typeof(Event) === 'function') {
            evt = new Event('resize');
        }else{
            evt = document.createEvent('Event');
            evt.initEvent('resize', true, true);
        }
        window.dispatchEvent(evt);
    }

    resize(w:number, h:number):void {
        let i:number;
        if (w >= 1600) {
            i = 5;
        } else if (w < 1600 && w >= 1200) {
            i = 4;
        } else if (w < 1200 && w >= 992) {
            i = 3;
        } else if (w < 992 && w >= 768) {
            i = 2;
        } else if (w < 768 && w >= 576) {
            i = 1;
        } else if (w < 576) {
            i = 0;
        }

        this.size.next(this.sizelist[i]);
    }

}
