import { Component, OnInit, ElementRef, Renderer, AfterViewInit } from '@angular/core';

import { UserService } from '../../core/user.service';
import { User } from '../../models/user.model';

@Component({
  selector: 'app-layout-header',
  templateUrl: './header.component.html',
  styles: [`
  
  /*헤더 - 배경색, 바닥보더*/
  .header, .header.header-1, .modal-backdrop, header, header.header-1 {
    background-color: #fff;
    border-bottom: 1px solid #efefef;
  }

  :host ::ng-deep .header-hamburger-trigger>span, :host ::ng-deep .header-hamburger-trigger>span::after, :host ::ng-deep .header-hamburger-trigger>span::before {
    background-color: #222;
  }
  /* 헤더 유저 아이콘 */
  .header .header-actions>.dropdown>.dropdown-toggle, .header .settings>.dropdown>.dropdown-toggle, header .header-actions>.dropdown>.dropdown-toggle, header .settings>.dropdown>.dropdown-toggle {
    color: #666;
  }
  /* 헤더 셋팅 아이콘 */
  .header .header-actions .nav-link, .header .settings .nav-link, header .header-actions .nav-link, header .settings .nav-link {
    color: #666;
  }
  `]
})
export class HeaderComponent implements OnInit, AfterViewInit {
  constructor(
    private userService: UserService,
    private element: ElementRef,
     private renderer: Renderer
  ) {}

  currentUser: User;

  ngOnInit() {
    // console.log('헤더 컴포넌트 인잇');
    this.userService.currentUser.subscribe(
      (userData) => {
        this.currentUser = userData;
      }
    );
    
 

  }

  ngAfterViewInit() {
    let elem = this.element.nativeElement.querySelector('.header-hamburger-trigger');
    
    console.log(elem);
  }

  logout() {
    this.userService.purgeAuth(); // 자동으로 isAuthenticated 상태가 전파됨으로 구독하고 있는 옵저버들에게 영향이 미친다.
  }
}
